import React from "react";
import { Link as GatsbyLink } from "gatsby";
import {
	Knobs,
	Example,
	DocPageMeta,
	InlineMessage,
	ImportExample,
	Header,
} from "../../../../../src/docs";
import { HeaderSection, ContentSection } from "../../../../../src/docs/sections";
import { Ul } from "../list/list";
import { Paragraph } from "../paragraph/paragraph";
import { TextContainer } from "../text-container/text-container";
import { InlineText } from "./inline-text";

export const Meta: DocPageMeta = {
	category: "Text",
	title: "Inline text",
	notepad: "https://hackmd.io/ATrEwSZiR1WJlUPQWcu2dQ",
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Inline text"
			subTitle="Use this component for single words or short snippets. It can be presented by itself or as part of sentence. Examples include data in tables and UI component labels."
		/>
		<ContentSection>
			<TextContainer article>
				<ImportExample component={InlineText} />
				<InlineMessage variant="warning">
					<Ul
						items={[
							<>
								If your text consists of one or more complete sentences, use the{" "}
								<GatsbyLink to="../Paragraph">paragraph</GatsbyLink> component instead.
							</>,
							<>
								If your text functions as the title of page or section, use the{" "}
								<GatsbyLink to="../Heading">heading</GatsbyLink> component instead.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H2>Examples</Header.H2>
				<Paragraph>
					The inline text component features various properties for customizing its appearance.
					Adjust the properties if it helps users complete their tasks. Specifically, by making the
					text's appearance match its message and importance. Don't make adjustments purely because
					of aesthetic reasons. The following sections showcase these properties and explain when to
					use them.
				</Paragraph>
				<Header.H3>Default</Header.H3>
				<Paragraph>
					The componenent's default appearance is best suited for neutral information of regular
					importance that does not need to be the first thing that user notices.
				</Paragraph>
				<Example fn={defaultExample} />
				<Header.H3>Size</Header.H3>
				<Paragraph>
					Use this property to adjust how attention-grabbing the text should be.
				</Paragraph>
				<Example fn={sizeExample} />
				<Header.H3>Tone</Header.H3>
				<Paragraph>
					Use this property to make the text's color support its semantic message.
				</Paragraph>
				<Example fn={toneExample} />
				<Header.H3>Emphasis</Header.H3>
				<Paragraph>
					Use this property to make the text's visual weight match the importance of its message.
				</Paragraph>
				<Example fn={emphasisExample} />
				<Header.H3>Line height</Header.H3>
				<Paragraph>
					This property is by default set to single-line, since single words or short snippets
					rarely wrap across multiple lines. However, when there is a possibility that this could
					happen, the property should be set to multi-line. An example is UI component labels, such
					as options in a dropdown, which can be quite long at times.
				</Paragraph>
				<Example fn={lineHeightExample} />
				<Header.H2>Properties</Header.H2>
				<Knobs
					component={InlineText}
					initialProps={{
						children: "Lorem ipsum",
					}}
					exampleValues={{
						children: {
							"short text": "Lorem ipsum dolor sit amet.",
							"longer text":
								"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magnaliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ecommodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nullpariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpqui officideserunt mollit anim id est laborum.",
						},
					}}
				/>
				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Ul
						items={[
							"Use for single words or short snippets. Examples include data in tables, UI component labels and parts of sentences that need to stand out.",
							"Use the styling properties for functional, not aesthetic, purposes.",
							<>
								When used as part of a sentence, make sure the size, line-height and alignment
								property values match those of the{" "}
								<GatsbyLink to="../Paragraph">paragraph</GatsbyLink> component it is nested inside
								of.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Ul
						items={[
							<>
								The text consists of one or more complete sentences. Use the{" "}
								<GatsbyLink to="../Paragraph">paragraph</GatsbyLink> component instead.
							</>,
							<>
								The text functions as the title of a page (section). Use the{" "}
								<GatsbyLink to="../Heading">heading</GatsbyLink> component instead.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<Paragraph>
						When the content of an inline text component is important, try to mention this in the
						text preceding the component. This benefits screenreader users, who may not be able to
						see the visual styling signaling the importance of the content.
					</Paragraph>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Paragraph>There are no writing guidelines for this component.</Paragraph>
				</InlineMessage>
				<Header.H2>Notable Changes</Header.H2>
				<Header.H3>Version 0.0.x</Header.H3>
				<Paragraph>
					The name of this component was changed from Span to InlineText when it was moved from
					FancyPFG to FancyLib.
				</Paragraph>
			</TextContainer>
		</ContentSection>
	</>
);

const defaultExample = () => <InlineText>default</InlineText>;

const sizeExample = () => (
	<>
		<InlineText size="xSmall">x-small</InlineText>
		<br />
		<InlineText size="small">small (default)</InlineText>
		<br />
		<InlineText size="medium">medium</InlineText>
		<br />
		<InlineText size="large">large</InlineText>
		<br />
		<InlineText size="xLarge">x-large</InlineText>
		<br />
		<InlineText size="xxLarge">xx-large</InlineText>
		<br />
		<InlineText size="xxxLarge">xxx-large</InlineText>
	</>
);

const toneExample = () => (
	<>
		<InlineText tone="neutralDark">neutral-dark</InlineText>
		<br />
		<div style={{ backgroundColor: "#222" }}>
			<InlineText tone="neutralLight">
				neutral-light (dark background) - background not included, just added so you can see here)
			</InlineText>
		</div>
		<br />
		<InlineText tone="positive">positive</InlineText>
		<br />
		<InlineText tone="negative">negative</InlineText>
		<br />
		<InlineText tone="warning">warning</InlineText>
		<br />
		<InlineText tone="subtle">subtle</InlineText>
	</>
);

const emphasisExample = () => (
	<>
		<InlineText emphasis="normal">normal (regular importance and default)</InlineText>
		<br />
		<InlineText emphasis="medium">medium (moderately important)</InlineText>
		<br />
		<InlineText emphasis="strong">strong (very important)</InlineText>
	</>
);

const lineHeightExample = () => (
	<>
		<InlineText lineHeight="single-line">
			The inline text component should not be used for sentences. However, an exception is made here
			to illustrate how the default single-line line-height setting negatively affects the reading
			experience.
		</InlineText>
		<br />
		<br />
		<InlineText lineHeight="multi-line">
			The inline text component should not be used for sentences. However, an exception is made here
			to illustrate how the multi-line line-height setting positively affects the reading
			experience.
		</InlineText>
	</>
);
